import React from 'react'
import Img from 'gatsby-image'
import { CheckedListContainer } from '../common/styledComponents'
import documentToReactComponents from '../../utils/richTextUtils'

export default function PhotoCardComponent({ content }) {
  const { contentful_id, content: { json }, images, pageTitle } = content
  const avatar = images[0]
  const image = images[1]

  return (
    <div className='flex w-full md:pt-8 pt-0' data-contentfulid={contentful_id}>
      <div className='flex w-full items-end'>
        <div className='flex-1 max-w-xs sm:ml-auto -ml-20'>
          <Img fluid={image.fluid} alt={image.title} />
        </div>
      </div>
      <div className='flex w-full sm:mr-auto sm:-ml-8 -ml-6'>
        <div className='py-10'>
          <div className='flex flex-col items-center bg-grey-100'>
            <div className='-mt-4'>
              <Img fluid={avatar.fluid} alt={image.title} className='w-12' />
            </div>
            <div className='py-3'>
              {pageTitle}
            </div>

          </div>
          <CheckedListContainer className='flex sm:px-10 px-6 sm:pb-10 pb-8 pt-4 bg-white'>
            {documentToReactComponents({
              richTextDocument: json
            })}
          </CheckedListContainer>
        </div>
      </div>
    </div>
  )
}

