import React, { useMemo } from 'react'
import styled from 'styled-components'
import ComponentConstructor from './ComponentConstructor'
import ContentLink from '../ContentLink'
import documentToReactComponents from '../../utils/richTextUtils'
import { Element as ScrollElement } from 'react-scroll'
import useLocalStorage from '../../utils/useLocalStorage'
import { ParseConfiguration } from '../../utils/helpers'
import { useUserContext } from '../../contexts/User/userContext'
import { currentActiveQuizKey } from '../../constants/localStorage'

const LandingFloorContainer = ({ content }) => {
  const { user } = useUserContext()
  const { configuration } = content
  const sectionBlocks = content.sectionBlocks
  const json = (content.content || {}).json

  const conditionSlug = useMemo(() => user.conditionSlug, [user])

  const {
    containerClass,
    containerStyle,
    className,
    style,
    linkClass,
    contentClass,
    contentStyle,
    anchorLink: { id: anchorId } = {}
  } = ParseConfiguration(configuration)

  const [currentQuizConditionSlug] = useLocalStorage(currentActiveQuizKey)

  const updateQuizIntroUrl = (url) => {
    return url
  }

  const updateLink = (sectionBlocks) => {
    return sectionBlocks.map((ref) => {
      const reference = { ...ref }
      if (reference.__typename === 'ContentfulLink' && reference.image) {
        reference.url = updateQuizIntroUrl(reference.url)
      }

      if (reference.__typename === 'ContentfulLink' && !reference.image) {
        let url = updateQuizIntroUrl(reference.url)
        let title = reference.title

        if (user.isAuth || user.enrolled) {
          // Logged in user or newly subscribed, navigate to member dashboard
          title = 'View My Care'
          url = `/mycare`
        } else if (conditionSlug) {
          // Completed quiz but no subscription, navigate to plan proposal page
          if (
            reference.url.startsWith(`/quiz/${conditionSlug}`) ||
            reference.url.startsWith('/select-condition')
          ) {
            title = 'Resume'
            url = `/quiz/${conditionSlug}?resume=true`
          }
        } else if (currentQuizConditionSlug) {
          // Unfinished quiz, navigate to resume taking quiz
          if (
            reference.url.startsWith(`/quiz/${currentQuizConditionSlug}`) ||
            reference.url.startsWith('/select-condition')
          ) {
            title = 'Resume'
            url = `/quiz/${currentQuizConditionSlug}?resume=true`
          } else {
            url = updateQuizIntroUrl(reference.url)
          }
        } else {
          reference.url = updateQuizIntroUrl(reference.url)
        }
        reference.url = url
        reference.title = title
      } else if (reference.__typename === 'ContentfulEvergreenContent') {
        reference.references = updateLink(reference.references || [])
      }
      return reference
    })
  }

  const updatedSections = updateLink(sectionBlocks || [])

  const Container = ({ children, ...props }) => {
    return anchorId ? (
      <ScrollElement className={containerClass} name={anchorId} {...props}>
        {children}
      </ScrollElement>
    ) : (
      <div className={containerClass} {...props}>
        {children}
      </div>
    )
  }

  const ContainerDiv = styled(Container)`
    ${containerStyle}
  `
  const OuterDiv = styled.div`
    ${style}
  `
  const ContentDiv = styled.div`
    ${contentStyle}
  `
  return (
    <ContainerDiv
      data-contentfulid={content.contentful_id}
      className={containerClass}
    >
      <OuterDiv
        className={`flex flex-wrap w-full max-w-content mx-auto md:py-16 py-8 lg:px-6 px-2 ${className || ''
          }`}
      >
        {json && (
          <ContentDiv
            className={`markdown ${contentClass ? contentClass : 'landing text-center w-full'
              }`}
          >
            {documentToReactComponents({
              richTextDocument: json
            })}
          </ContentDiv>
        )}
        {sectionBlocks &&
          updatedSections.map((reference) => {
            const { componentType: _componentType, configuration } = reference
            const { componentType: _configComponentType } = ParseConfiguration(
              configuration
            )
            const componentType = _componentType || _configComponentType
            const Component =
              componentType && ComponentConstructor[componentType]
            if (reference.__typename === 'ContentfulEvergreenContent') {
              return (
                Component && (
                  <Component key={reference.id} content={reference} />
                )
              )
            } else if (reference.__typename === 'ContentfulLink') {
              return (
                <div
                  key={reference.id}
                  data-contentfulid={reference.contentful_id}
                  className="flex justify-center w-full md:py-10"
                >
                  <ContentLink
                    link={reference}
                    className={`btn ${linkClass ? linkClass : 'btn--primary'}`}
                  />
                </div>
              )
            }
            return null
          })}
      </OuterDiv>
    </ContainerDiv>
  )
}

export default LandingFloorContainer
