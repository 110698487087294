import styled from 'styled-components'

export const CheckedListContainer = styled.div`
  li {
    display: flex;
    align-items: center;
    list-style-type: none !important;
    padding-bottom: 0.75rem;
    :before{
      content: '✓';
      margin-left: -1rem;
      margin-right: 0.75rem;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #372c72; 
      padding: 10px;
    }
  }
`

export const CheckedListPurpleCheckmark = styled.div`
  li {
    display: flex;
    align-items: center;
    list-style-type: none !important;
    padding-bottom: 0.75rem;
    :before{
      content: '✓';
      margin-left: -1rem;
      margin-right: 0.25rem;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #372c72;
      padding: 10px;
    }
  }
`