import React from 'react'
import styled from 'styled-components'
import CarouselContainer from '../../Carousel/CarouselContainer'
import ComponentConstructor from '../ComponentConstructor'
import { ParseConfiguration } from '../../../utils/helpers'
import documentToReactComponents from '../../../utils/richTextUtils';

const CarouselComponent = ({ content }) => {
  const { configuration, references } = content
  const {
    className,
    style,
    sliderSettings: _sliderSettings
  } = ParseConfiguration(configuration)

  const ContainerDiv = styled.div`
    ${style}
  `

  const sliderSettings = _sliderSettings || {
    centerMode: true,
    centerPadding: '0px',
    className: 'center',
    dots: false,
    initialSlide: 1,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 0,
          className: ''
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 0,
          className: ''
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 0,
          className: ''
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 0,
          className: ''
        }
      }
    ]
  }

  return references ? (
    <>
      <ContainerDiv
        data-contentfulid={content.contentful_id}
        className={`${
          className ? className : 'flex justify-center w-full px-10 flex-row'
          }`}
      >
        {content.content && content.content.json && <div
          className="max-w-5xl w-full mx-auto"
          data-contentfulid={content.contentful_id}
        >
          <div className="markdown landing mx-6 text-center text-black">
            {documentToReactComponents({
              richTextDocument: content.content.json
            })}
          </div>
        </div>}
        <CarouselContainer sliderSettings={sliderSettings}>
          {references &&
            references.map(reference => {
              const { componentType } = reference
              const Component =
                componentType && ComponentConstructor[componentType]
              return (
                Component && (
                  <Component key={reference.id} content={reference} />
                )
              )
            })}
        </CarouselContainer>
      </ContainerDiv>
    </>
  ) : null
}

export default CarouselComponent
