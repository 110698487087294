import ImageComponent from './components/ImageComponent'
import RichTextComponent from './components/RichTextComponent'
import RichTextListComponent from './components/RichTextListComponent'
import CarouselComponent from './components/CarouselComponent'
import CardComponent from './components/CardComponent'
import QaComponent from './components/QaComponent'
import MedicalTeamComponent from './components/MedicalTeamComponent'
import KlaviyoFormComponent from './components/KlaviyoFormComponent'
import TestimonialCardComponent from './components/TestimonialCardComponent'
import BgImageComponent from './components/BgImageComponent'
import PhotoCardComponent from '../Homepage/PhotoCardComponent'
import UserCardComponent from './components/UserCardComponent'

const ComponentConstructor = {
  'Image Component': ImageComponent,
  'BgImage Component': BgImageComponent,
  'RitchText Component': RichTextComponent,
  'RitchTextList Component': RichTextListComponent,
  'Carousel Component': CarouselComponent,
  'Card Component': CardComponent,
  'QA Component': QaComponent,
  'MedicalTeam Component': MedicalTeamComponent,
  'KlaviyoForm Component': KlaviyoFormComponent,
  'TestimonialCard Component': TestimonialCardComponent,
  'PhotoCard Component': PhotoCardComponent,
  'UserCard': UserCardComponent
}

export default ComponentConstructor
