import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { ParseConfiguration } from '../../../utils/helpers'

const BgImageComponent = ({ content }) => {
  const { images, configuration } = content
  const {
    className, style, bgImageStyle, desktopImageIndex = 1,
    mobileImageIndex
  } = ParseConfiguration(configuration)

  const desktopImage = desktopImageIndex &&
    images.length >= desktopImageIndex - 1 && images[desktopImageIndex - 1]
  const mobileImage = mobileImageIndex &&
    images.length >= mobileImageIndex - 1 && images[mobileImageIndex - 1]

  const ImgDiv = styled.div`
    ${style}
  `

  const StyledBackgroundSection = styled(BackgroundImage)`
    ${bgImageStyle}
    
  `

  return (
    <>
      {desktopImage && (
        <ImgDiv
          className={`${className || 'md:w-1/2 w-full'}`}
          data-contentfulid={content.contentful_id}
        >
          <StyledBackgroundSection
            className={`${mobileImage && 'sm:block hidden'}`}
            fluid={desktopImage.fluid}
            alt={desktopImage.title}
          >
          </StyledBackgroundSection>
          {mobileImage &&
          <StyledBackgroundSection
            className='sm:hidden'
            fluid={mobileImage.fluid}
            alt={mobileImage.title}
          >
          </StyledBackgroundSection>
          }
        </ImgDiv>
      )}
    </>
  )
}

export default BgImageComponent
