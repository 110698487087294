import React from 'react'
import styled from 'styled-components'
import documentToReactComponents from '../../../utils/richTextUtils'
import ContentLink from '../../ContentLink'
import { ParseConfiguration } from '../../../utils/helpers'

const QaComponent = ({ content }) => {
  const {
    content: { json },
    configuration,
    pageTitle,
    references
  } = content
  const {
    containerClass,
    containerStyle,
    titleClass,
    titleStyle,
    contentClass,
    contentStyle,
    linkClass
  } = ParseConfiguration(configuration)
  const link = (references || []).find((r) => r.__typename === 'ContentfulLink')

  const ContainerDiv = styled.div`
    ${containerStyle}
  `
  const TitleDiv = styled.div`
    ${titleStyle}
  `
  const ContentDiv = styled.div`
    ${contentStyle}
  `

  return (
    <>
      <hr
        className="mx-auto border-gp-violet-30"
        style={{ borderWidth: '1.5px' }}
      />
      <ContainerDiv
        data-contentfulid={content.contentful_id}
        className={`${
          containerClass
            ? containerClass
            : 'flex flex-col justify-center w-full py-10'
        } markdown landing embedded-entry`}
      >
        <div className="flex flex-wrap">
          {pageTitle && (
            <TitleDiv
              className={
                titleClass ||
                'text-3xl text-primary-base md:pr-10 bold md:w-5/12 w-full'
              }
            >
              <h3>{pageTitle}</h3>
            </TitleDiv>
          )}
          {json && (
            <ContentDiv
              className={
                contentClass ||
                'flex hr-primary-base flex-1 md:w-7/12 w-full md:-mt-6'
              }
            >
              <div className="flex-1">
                {documentToReactComponents({
                  richTextDocument: json
                })}
              </div>
              {link && (
                <div className="pt-10 text-right bg-white">
                  <ContentLink className={linkClass} link={link} />
                </div>
              )}
            </ContentDiv>
          )}
        </div>
      </ContainerDiv>
    </>
  )
}

export default QaComponent
