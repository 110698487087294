import React from 'react'
import Img from 'gatsby-image'
import documentToReactComponents from '../../../utils/richTextUtils'

const UserCardComponent = ({ content }) => {
  const {
    content: { json },
    pageTitle,
    metaDescription,
    images
  } = content

  const cardImage = images.length && images[0]
  const userImage = images.length > 1 && images[1]

  return (
    <>
      <div data-contentfulid={content.contentful_id} className="mx-auto">
        <div className="flex flex-col h-full bg-white">
          {pageTitle && (
            <div className="flex flex-col items-center text-2xl bold text-center">
              <h2 className="pb-8">{pageTitle}</h2>
              <hr className="w-20" />
            </div>
          )}
          <div className="flex flex-col items-center max-w-md">
            {userImage && (
              <div className="pt-10">
                <Img
                  fluid={userImage.fluid}
                  alt={userImage.title}
                  style={{ width: '10rem' }}
                />
              </div>
            )}
            {metaDescription && (
              <div className="text-xl bold text-primary-base py-6">
                {metaDescription}
              </div>
            )}
            {json && (
              <div className={'flex flex-col py-2 px-6 hr-primary-base flex-1'}>
                <div className="flex-1 text-center">
                  {documentToReactComponents({
                    richTextDocument: json
                  })}
                </div>
              </div>
            )}
            {cardImage && (
              <div className="w-full pt-10">
                <Img fluid={cardImage.fluid} alt={cardImage.title} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UserCardComponent
