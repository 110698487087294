import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../SEO'
import LandingFloorContainer from './LandingFloorContainer'
import AnchorLinks from './components/AnchorLinks'
import { ParseConfiguration } from '../../utils/helpers'

const LandingPageContainer = ({ data, content, location }) => {
  const _content = (data || {}).content || content
  const {
    pageTitle,
    metaDescription,
    noIndex,
    canonicalLink,
    sections,
    configuration
  } = _content
  const { className, anchorLinks, style } = ParseConfiguration(configuration)

  const ContainerDiv = styled.div`
    ${style}
  `

  return (
    <>
      <SEO
        title={pageTitle}
        description={metaDescription}
        //image={imageUrl}
        location={location}
        canonicalLink={canonicalLink}
        noIndex={noIndex}
      />
      <ContainerDiv
        data-contentfulid={_content.contentful_id}
        className={`w-full ${className ? className : 'mx-auto'}`}
      >
        {anchorLinks && <AnchorLinks references={sections} />}
        {sections &&
          sections.map((content) => (
            <LandingFloorContainer
              key={content.id}
              content={content}
              location={location}
            />
          ))}
      </ContainerDiv>
    </>
  )
}

// TODO: update that one for new condition (contentful change)
export const query = graphql`
  query($id: String!) {
    content: contentfulPage(id: { eq: $id }, node_locale: { eq: "en-US" }) {
      id
      contentful_id
      pageTitle
      noIndex
      metaDescription
      canonicalLink {
        title
        url
      }
      configuration {
        internal {
          content
        }
      }
      sections {
        id
        contentful_id
        name
        configuration {
          internal {
            content
          }
        }
        content {
          json
        }
        sectionBlocks {
          ... on ContentfulEvergreenContent {
            id
            contentful_id
            pageTitle
            metaDescription
            name
            content {
              json
            }
            images {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              title
            }
            componentType
            configuration {
              internal {
                content
              }
            }
            references {
              ... on ContentfulLink {
                id
                contentful_id
                title
                url
                isInternal
              }
              ... on ContentfulEvergreenContent {
                id
                contentful_id
                name
                pageTitle
                metaDescription
                content {
                  json
                }
                configuration {
                  internal {
                    content
                  }
                }
                images {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  title
                }
                componentType
                references {
                  ... on ContentfulLink {
                    id
                    contentful_id
                    title
                    url
                    isInternal
                  }
                  ... on ContentfulEvergreenContent {
                    id
                    contentful_id
                    name
                    pageTitle
                    metaDescription
                    content {
                      json
                    }
                    configuration {
                      internal {
                        content
                      }
                    }
                    componentType
                  }
                }
              }
            }
          }
          ... on ContentfulLink {
            id
            contentful_id
            title
            url
            isInternal
          }
        }
      }
    }
  }
`

export default LandingPageContainer
