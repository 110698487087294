import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import StarRatingComponent from 'react-star-rating-component'
import documentToReactComponents from '../../../utils/richTextUtils'
import ContentLink from '../../ContentLink'
import { ParseConfiguration } from '../../../utils/helpers'

const TestimonialCardComponent = ({ content }) => {
  const {
    id,
    content: { json },
    configuration,
    pageTitle,
    metaDescription,
    references,
    images
  } = content
  const {
    containerClass,
    containerStyle,
    contentClass,
    contentStyle,
    linkClass,
    rating
  } = ParseConfiguration(configuration)
  const link = (references || []).find((r) => r.__typename === 'ContentfulLink')
  const image = (images || [])[0]

  const ContainerDiv = styled.div`
    ${containerStyle}
  `
  const ContentDiv = styled.div`
    ${contentStyle}
  `

  return (
    <>
      <ContainerDiv
        data-contentfulid={content.contentful_id}
        className={`${
          containerClass
            ? containerClass
            : 'flex flex-col justify-center w-full py-10 px-4'
        } markdown landing`}
      >
        <div className="flex flex-col h-full bg-white p-8 shadow-lg">
          {pageTitle && (
            <div className="flex">
              <div>
                <Img fluid={image.fluid} className="w-20" />
              </div>
              <div className="flex flex-col bold flex-1 justify-center pl-4">
                <div className="text-lg text-primary-base pb-1">
                  {pageTitle}
                </div>
                <div className="text-grey-667 text-base">{metaDescription}</div>
              </div>
            </div>
          )}
          {json && (
            <ContentDiv
              className={
                contentClass || 'flex flex-col py-4 hr-primary-base flex-1'
              }
            >
              <div className="flex-1">
                {documentToReactComponents({
                  richTextDocument: json
                })}
              </div>
              {link && (
                <div className="pt-10 text-right bg-white">
                  <ContentLink className={linkClass} link={link} />
                </div>
              )}
            </ContentDiv>
          )}
          <StarRatingComponent
            name={id}
            value={rating}
            editing={false}
            starColor="#fae27a"
            emptyStarColor="#E5E5E5"
          />
        </div>
      </ContainerDiv>
    </>
  )
}

export default TestimonialCardComponent
