import React from 'react'
import styled from 'styled-components'
import RichTextComponent from './RichTextComponent'
import ComponentConstructor from '../ComponentConstructor'
import ContentLink from '../../ContentLink'
import { ParseConfiguration } from '../../../utils/helpers'

const RichTextListComponent = ({ content }) => {
  const { configuration, references } = content
  const { className, style, linkClass } = ParseConfiguration(configuration)

  const ContainerDiv = styled.div`
    ${style}
  `

  return references ? (
    <>
      <ContainerDiv
        data-contentfulid={content.contentful_id}
        className={`${
          className
            ? className
            : 'flex flex-col justify-center md:w-1/2 w-full p-10'
        }`}
      >
        {references &&
          references.map(reference => {
            if (reference.__typename === 'ContentfulLink') {
              return (
                <ContentLink
                  key={reference.id}
                  link={reference}
                  className={linkClass || 'rounded-lg'}
                />
              )
            }

            const { componentType } = reference
            const Component =
              (componentType && ComponentConstructor[componentType]) ||
              RichTextComponent
            return <Component key={reference.id} content={reference} />
          })}
      </ContainerDiv>
    </>
  ) : null
}

export default RichTextListComponent
