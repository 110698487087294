import React from 'react'

const KlaviyoFormComponent = ({ content }) => {
  const { readableId } = content
  return (
    <div className={readableId} data-contentfulid={content.contentful_id} />
  )
}

export default KlaviyoFormComponent
