import React, { useState } from 'react'
import classNames from 'classnames'
import { Link as ScrollLink } from 'react-scroll'
import { ParseConfiguration } from '../../../utils/helpers'

const AnchorLinks = ({ references }) => {
  const [activeLink, setActiveLink] = useState(null)

  return (
    <div className="md:flex w-full mx-auto py-5 hidden fixed left-0 bg-white z-40 -mt-3 tracking-wider">
      <div className="flex w-full lg:max-w-5xl mx-auto bg-white px-3 lg:px-2 justify-center">
        {references.map((ref, index) => {
          const { configuration } = ref
          const { anchorLink } = ParseConfiguration(configuration)
          const { id, title } = anchorLink || {}

          const klassNames = classNames('', {
            'pr-8 -mx-2': index === 0,
            'px-8': index > 0,
            hidden: !title
          })

          const scrollklassNames = classNames(
            'text-grey-667 text-center leading-tight focus:outline-none no-underline cursor-pointer px-2 font-semibold pb-50 border-primary-base',
            {
              'border-b-2': id && activeLink && activeLink.startsWith(id)
            }
          )

          return id ? (
            <div key={index} className={klassNames}>
              <ScrollLink
                smooth={true}
                to={id}
                duration={500}
                offset={-130}
                spy={true}
                hashSpy={false}
                className={scrollklassNames}
                style={{ paddingBottom: '19px' }}
                onSetActive={(e) => setActiveLink(e)}
              >
                {title}
              </ScrollLink>
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}

export default AnchorLinks
